<template>
  <ModalMainNew
    modal-width-mode="md"
    hide-close
    secondary-background-color
    @close="onCloseModal"
  >
    <div class="animate-slide-down-fade-in01s">
      <h2 class="text-xl font-bold uppercase mb-5 flex items-center">
        HODL <span class="px-1 py-1 text-2xs rounded-md font-600 bg-blue-500/20 ml-1 lowercase">beta</span>
      </h2>
      <p class="text-gray-400 text-sm mb-5">
        HODL game play is not covered by our gaming license and is offered by MetaWin independently.
      </p>
      <ButtonButton
        theme="primary"
        class="uppercase mb-4"
        size="sm"
        @click.prevent.stop="onAcknowledge"
      >
        I Acknowledge
      </ButtonButton>
    </div>
  </ModalMainNew>
</template>

<script setup>
const uiStore = useUiStore();
const {
  showTradingLicenseModal,
} = storeToRefs(uiStore);

const tradingStore = useTradingStore();

const {
  license,
} = storeToRefs(tradingStore);

function onAcknowledge() {
  const now = new Date();
  license.value = now.getTime();
  showTradingLicenseModal.value = false;
}

function onCloseModal() {
  showTradingLicenseModal.value = false;
}
</script>
